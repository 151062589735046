@font-face {
  font-family: "Satoshi Light";
  src: url(/assets/fonts/Satoshi-Light.ttf);
}

$font-1: "Satoshi Light";
:root {
  --light-black: #0b0b0b;
  --grey: #5e5e5e;
  --taupe: #323232;
  --white: #fafafa;
  --transparent-white: #ffffffb6;
  --transparent-black: #42424254;
  --olive-green: #96c8b5db;
  --light-olive-green: #89b6a4;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: $font-1;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  user-select: none;
}

h1,
h2,
h3 {
  letter-spacing: 0.05rem;
  font-weight: 900;
}

body {
  margin: 0;
  padding: 0;
  color: var(--light-black);
  text-align: center;
  scroll-behavior: smooth;
  height: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button {
  background: var(--olive-green);
  color: var(--white);
  border: 1px solid var(--white);
  font-size: 1rem;
  padding: 3px 8px;
  border-radius: 5px;
  transition: 0.3s;
  &:hover {
    background: var(--light-olive-green);
    cursor: pointer;
  }
}
.icon {
  font-size: 1.1rem;
  margin: 5px;

  &:hover {
    filter: invert(34%) sepia(12%) saturate(17%) hue-rotate(349deg)
      brightness(97%) contrast(82%);
    cursor: pointer;
  }
}
.icon-white {
  filter: invert(99%) sepia(100%) saturate(1%) hue-rotate(192deg)
    brightness(103%) contrast(96%) !important;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgb(245, 245, 245);
  }
  100% {
    background-color: rgb(230, 230, 230);
  }
}
.skeleton {
  animation: skeleton-loading 2s linear infinite alternate;
}
