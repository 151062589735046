.imgSlider {
  overflow: hidden;
  margin: 0 0 25px;
  padding: 5px;
  .carousel-gallery-modal {
    position: fixed;
    left: 0;
    top: 0;
    background: #ffffff34;
    backdrop-filter: blur(2px);
    height: 100vh;
    height: 100svh;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 3000;
    .close-modal-icon {
      z-index: 3100;
      position: absolute;
      top: 40px;
      left: 40px;
      font-size: 2.5rem;
      transition: 0.3s;

      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
    .carousel {
      user-select: none;
      height: 100%;
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      img {
        max-width: 90%;
        max-height: 90vh;
        max-height: 90svh;
        /*     height: 100%; */
        object-fit: cover;
      }
      @media screen and (max-width: 500px) {
        img {
          max-width: 100%;
        }
      }
      .swiper-pagination-bullet,
      .swiper-pagination-bullet-active {
        background: #fdc178;
      }
      .swiper-button-next,
      .swiper-button-prev {
        color: #fdc178;
      }
    }
  }
  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-rows: 200px 200px;
    gap: 10px;
    grid-template-areas:
      "main sec sec"
      "main sec sec";
    width: 100%;
    position: relative;
    @media screen and (max-width: 680px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 140px 140px 140px;
      grid-template-areas:
        "main main"
        "sec sec"
        "sec sec";
    }
    .main {
      grid-area: main;
    }
    .button-show-pictures {
      position: absolute;
      bottom: 10px;
      left: 10px;

      @media screen and (max-width: 430px) {
        left: auto;
        right: 10px;
      }
    }
    .img-container {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      overflow: hidden;
      cursor: pointer;

      .img-slider {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 1s;
        &:hover {
          scale: 1.04;
        }
      }
    }
  }
}
