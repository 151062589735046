.footer {
  padding: 15px;
  display: grid;
  margin-top: auto;
  width: 100%;
  align-self: flex-end;
  justify-content: space-around;
  align-items: center;
  background: var(--taupe);
  grid-template-columns: 20% 52% 28%;
  color: var(--white);
  .attributions {
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 12px;
    padding-right: 25px;
    font-size: 0.9rem;
    .by-victor {
      display: flex;
      align-items: center;
      gap: 12px;
      transition: 0.3s;
      &:hover {
        color: var(--grey);
        cursor: pointer;
      }
    }
    &::after {
      content: "";
      position: absolute;
      width: 0.025rem;
      height: 100%;
      background: #ffffff;
      right: 0;
      top: 0;
    }
  }
  .text-and-logo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    text-align: left;
    height: 100%;
    padding: 0 30px;
    .text-footer {
      font-size: 1.1rem;
    }
    .logo {
      height: 70px;
    }
  }
  .contact-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding-left: 25px;
    gap: 5px;
    height: 100%;
    .person-contact {
      display: flex;
    }
    .contact-infos {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
    }
    .contact-phone,
    .contact-email {
      display: flex;

      align-items: center;
      transition: 0.3s;
      &:hover {
        color: var(--grey);
        cursor: pointer;
      }
    }
    .contact-phone {
      a {
        font-size: 0.9rem;
      }
    }

    .img-contact {
      height: 70px;
      width: 70px;
      border-radius: 500px;
      object-fit: cover;
    }
    h5 {
      font-size: 1rem;
      letter-spacing: 2px;
      padding-left: 5px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 0.025rem;
      height: 100%;
      background: #ffffff;
      left: 0;
      top: 0;
    }
  }
  @media screen and (max-width: 840px) {
    display: flex;
    padding: 15px;
    .text-and-logo {
      display: none;
    }
    .attributions {
      position: initial;
      padding-right: 5px;
      gap: 6px;
    }
    .contact-footer {
      flex-direction: row;
      align-items: center;
      padding-left: 10px;
    }
  }
  @media screen and (max-width: 490px) {
    .contact-footer {
      flex-direction: column;
      align-items: flex-start;
    }
    .attributions {
      gap: 14px;
    }
  }
  @media screen and (max-width: 410px) {
    flex-direction: column-reverse;
    gap: 10px;

    padding: 15px 5px;
    .attributions {
      gap: 5px;
    }
    .contact-footer {
      align-self: stretch;
      padding-left: 0px;
      .contact-email {
        font-size: 0.9rem;
      }

      .contact-phone {
        a {
          font-size: 0.8rem;
          letter-spacing: 0.05rem;
        }
      }
      align-items: center;
      flex-direction: row;

      gap: 5px;
      justify-content: space-around;
      position: initial;
      .icon {
        font-size: 0.9rem;
      }
    }
  }
}
