.infos {
  background: url(/assets/img/beach-picture.jpg) center/cover;
  height: 70vh;
  height: 70svh;
  background-attachment: fixed;
  display: flex;
  align-items: center;
  @media (hover: none) {
    background-attachment: initial;
  }
  .img-infos {
    padding: 5%;
    background: #42424254;
    display: flex;
    gap: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    color: white;
    letter-spacing: 2px;
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-weight: 400;
      @media screen and (max-width: 540px) {
        display: none;
      }
    }
  }
}

.map-contact {
  padding-top: 30px;
  background-color: rgb(223, 223, 208);
  width: 100%;
  .map-container {
    width: 100%;
    height: 100%;
  }
  .map {
    margin-top: 20px;
    width: 100%;
    &:focus {
      border: none;
    }
  }

  .contact-home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 35px;
    height: 100%;
    font-size: 1.1rem;
    .contact-phone,
    .contact-email {
      display: flex;
      gap: 5px;
      align-items: flex-end;
    }
    .contact-phone {
      a {
        font-size: 0.9rem;
      }
    }
    .person-contact {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      .img-contact {
        height: 80px;
        width: 80px;
        border-radius: 500px;
        object-fit: cover;
      }
      h5 {
        font-size: 1.2rem;
      }
    }
  }
}
