@keyframes anim {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes anim-translateY {
  from {
    transform: translateY(-350px);
  }
  to {
    transform: translateY(0px);
  }
}
@keyframes anim-trans-height {
  0% {
    transform: translateY(-350px);
    height: 0;
  }
  50% {
    transform: translateY(0);
    height: 100%;
  }
  100% {
    transform: translateY(0);
  }
}

nav {
  user-select: none;
  transition: 0.4s linear;
  height: 80px;
  background: rgb(255, 255, 255);
  display: flex;
  z-index: 3000;
  position: fixed;
  width: 100%;
  padding: 0 5%;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 20px -2px rgb(0, 0, 0, 0.25);
  &:hover {
    background: rgb(255, 255, 255);
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    max-height: 70px;
  }
  .menu {
    .icon {
      transition: 0.3s;
    }
    font-size: 1.1rem;
    display: flex;
    gap: 30px;
    height: 100%;
    align-items: center;
    .login-navbar {
      display: flex;
      gap: 10px;
      align-items: center;
      .login-icon,
      .logout-icon {
        cursor: pointer;
      }
      .inputs-login {
        display: flex;
        gap: 10px;
        .email-login,
        .password-login {
          display: flex;
          gap: 5px;
          input {
            padding-left: 5px;
          }
        }
      }
    }

    .contact-navbar-container,
    .dropdown-button {
      display: flex;
      align-items: center;
      gap: 5px;
      transition: 0.3s;
      &:hover {
        filter: invert(34%) sepia(12%) saturate(17%) hue-rotate(349deg)
          brightness(97%) contrast(82%);
        cursor: pointer;
      }
    }
    @media screen and (max-width: 630px) {
      gap: 12px;
      .dropdown-button,
      .contact-navbar-container {
        h4 {
          display: none;
        }
      }
    }
  }
  @media screen and (max-width: 330px) {
    .logo {
      max-height: 60px;
    }
  }
  @media screen and (max-height: 320px) {
    height: 70px;
  }
}
.dropdown {
  z-index: 2900;
  height: 300px;
  position: fixed;
  width: 100%;
  padding-top: 80px;
  @media screen and (max-height: 320px) {
    padding-top: 70px;
  }
  .dropdown-first-container {
    z-index: 1010;
    height: 100%;
    background-color: rgb(223, 223, 208);
    color: rgb(51, 51, 51);
    animation: anim-trans-height 0.9s;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
    gap: 10px;
    .dropdown-element {
      border: solid 1px rgb(214, 214, 214);
      background-color: rgb(242, 242, 242);
      border-radius: 5px;
      height: 100%;
      width: 100%;
      max-width: 600px;
      padding: 7px;
      animation: anim-translateY 1.1s;
      transition: 0.7s;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 10px;
      &:hover {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
        cursor: pointer;
        .dropdown-element-text {
          background: rgba(255, 255, 255, 0.888) !important;
          h3,
          p {
            color: var(--grey);
          }
        }
      }

      .img-dropdown {
        border-radius: 5px;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
      .dropdown-element-text {
        position: absolute;
        background: var(--transparent-white);
        left: 50%;
        top: 50%;
        width: 100%;
        padding: 3px 0;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        h3 {
          font-size: 1.4rem;
        }
        p {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }
  .dropdown-second-container {
    position: absolute;
    background: var(--taupe);

    width: 100%;
    height: 30px;
    animation: anim-trans-height 0.9s;
    bottom: -30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: var(--white);
      font-weight: 500;
      animation: anim-trans-height 0.9s;
    }
  }

  @media screen and (max-width: 810px) {
    height: 200px;
    .dropdown-first-container {
      padding: 10px;
      .dropdown-element {
        .dropdown-element-text {
          justify-content: space-between;
          h3 {
            font-size: 1rem;
          }
          p {
            font-size: 0.9rem;
          }
        }
      }
    }
    .dropdown-second-container {
      background: #e7e7e7;
      height: 15px;
      bottom: -15px;
      p {
        display: none;
      }
    }
  }

  @media screen and (max-width: 440px) {
    .dropdown-first-container {
      .dropdown-element {
        .dropdown-element-text {
          h3 {
            display: none;
          }
        }
      }
    }
  }
}
