.accommodation-page {
  background: rgb(246, 246, 246);
  text-align: left;
  position: relative;

  .accommodation-main {
    max-width: 90%;
    margin: 0 auto;

    @media screen and (max-width: 530px) {
      max-width: 93%;
    }
    .header-flex {
      padding-top: 80px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .title-share-accommodation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }
      .title {
        padding: 20px 0;
        h1 {
          font-size: 2.5rem;
        }
        @media screen and (max-width: 380px) {
          padding-left: 5px;
          h1 {
            font-size: 1.9rem;
          }
        }

        h3 {
          font-size: 1.1rem;
          @media screen and (max-width: 380px) {
            font-size: 0.9rem;
          }
        }
      }

      @media screen and (max-width: 620px) {
        flex-direction: column;

        .title-share-accommodation {
          h1 {
            font-size: 1.6rem;
          }
        }
      }
      .add-pictures-form {
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 7px;
        .pictures-form-inputs {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
        }
        img {
          height: 18px;
          width: 18px;
        }
        .add-pictures-btn {
          font-size: 1.1rem;
          border: 1px solid #525252;
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 6px 0;
          width: 60px;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        }
        input[type="file"] {
          font-size: 1rem;
          display: none;
        }
        .submit-pictures {
          display: flex;
          align-items: center;
          gap: 20px;
          input[type="submit"] {
            font-size: 1.1rem;
            background: #4d906f;
            color: white;
            border: none;
            padding: 6px 0;
            width: 80px;
            border-radius: 4px;
            font-weight: 600;
            cursor: pointer;
          }
        }
        @media screen and (max-width: 525px) {
          flex-direction: column;
          align-items: start;
          padding-left: 5px;
        }
      }
      @media screen and (max-width: 970px) {
        display: flex;
        flex-direction: column;
      }
    }

    .description-booking {
      display: flex;
      position: relative;
      padding: 0px 0px 25px;
      gap: 25px;

      .descr-video {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-width: 60%;

        .video-accomodation {
          cursor: pointer;
          aspect-ratio: 16/9;
          width: 100%;
          border-radius: 3px;
          object-fit: cover;
          transition: 0.8s;
        }

        .description {
          justify-content: space-between;
          display: flex;
          flex-direction: column;
          gap: 20px;
          z-index: 2;
          height: 100%;
          .amenities {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 2px;
            font-size: 1.1rem;
            & > div {
              background: white;
              display: flex;
              gap: 5px;
              align-items: center;
              justify-content: center;
              border: 0.01rem solid rgb(227, 227, 227);
              padding: 4px 5px;
              border-radius: 5px;
              img {
                height: 15px;
                width: 15px;
              }
            }
          }
          h2 {
            position: relative;
            max-width: 300px;
          }
          .important-message {
            display: flex;
            align-items: center;
            gap: 6px;
            color: rgb(108, 145, 131);
            h4 {
              letter-spacing: 1px;
            }
          }
          p {
            font-size: 1.1rem;
          }

          .check-inOut {
            display: flex;
            gap: 25px;
          }
        }
      }

      @media screen and (max-width: 925px) {
        flex-direction: column;
        .descr-video {
          max-width: 100%;
        }
      }
    }
  }
}
