.pictures-editor {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px 0;
  .images-editor-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    .image-container {
      width: calc(100% / 4 - 15px);
      position: relative;
      min-height: 200px;
      .delete-picture-button {
        position: absolute;
        top: 15px;
        right: 15px;
      }
      .image-editor {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .images-editor-container {
      gap: 10px;
      .image-container {
        width: calc(100% / 2 - 5px);
      }
    }
  }
}
