.switch-mode {
  margin: 5px;
  display: flex;
  gap: 6px;
  align-items: center;
  background: var(--olive-green);
  margin-bottom: 10px;
  border: none;
  font-size: 1rem;
  justify-content: center;
  border-radius: 5px;
  transition: 0.3s;
  h4 {
    color: var(--white);
    font-weight: 400;
  }
  &:hover {
    background: var(--light-olive-green);
    cursor: pointer;
  }
}
.edit-calendar-wrapper {
  display: flex;
  gap: 30px;
  padding-bottom: 25px;
  .calendar-and-edit {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    position: relative;
    .calendar-edit {
      width: 100%;
      max-width: 600px;
    }
    .pictures-form-edit-panel {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .edit-panel {
      top: 20%;
      position: sticky;
      height: min-content;
      background: #ffffff;
      border-radius: 10px;
      padding: 20px;
      display: flex;
      flex-direction: column;

      gap: 20px;
      .nights-price {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;

        .nights {
          font-size: 1rem;
        }
        .price {
          display: flex;
          justify-content: space-between;

          width: 110px;
          h3 {
            font-size: 1rem;
          }
          input {
            border: none;
            background: none;
            font-size: 1rem;
            width: 55px;
            font-weight: 600;
            &:focus {
              border: 0 none;
              outline: none;
            }
          }
        }
      }
      .red-button {
        background: #b85252 !important;
      }
      .edit-options {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .edit-section {
          display: flex;
          flex-direction: column;
          gap: 8px;
          button {
            max-width: 100% !important;
            width: 100%;
          }
          input {
            border-radius: 4px;
            border: 0.02rem solid gray;
            max-width: 60px;
            padding: 5px;
            max-height: 22px;
          }
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .header-and-input {
            align-items: center;
            justify-content: space-between;
            display: flex;
            gap: 10px;
          }
          .switch {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 23px;
            input {
              opacity: 0;
              width: 0;
              height: 0;
            }
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #b85252;
              -webkit-transition: 0.4s;
              transition: 0.4s;
              &:before {
                position: absolute;
                content: "";
                height: 15px;
                width: 15px;
                left: 4px;
                bottom: 4px;
                background-color: white;
                -webkit-transition: 0.4s;
                transition: 0.4s;
              }
            }
            input:checked + .slider {
              background-color: var(--olive-green);
            }

            input:focus + .slider {
              box-shadow: 0 0 1px #a7c2a3;
            }

            input:checked + .slider:before {
              -webkit-transform: translateX(15px);
              -ms-transform: translateX(15px);
              transform: translateX(15px);
            }

            .slider.round {
              border-radius: 34px;
            }

            .slider.round:before {
              border-radius: 50%;
            }
          }
        }
      }
    }
    @media screen and (max-width: 700px) {
      gap: 0px;
      flex-direction: column;
      .edit-panel {
        gap: 10px;
        .header-edit-panel {
          display: none;
        }
        border-radius: 0;
      }
    }
  }
}
