.calendar-wrapper {
  display: flex;

  width: 100%;

  justify-content: space-around;
  flex-direction: column;
  background: #ffffff;
  padding: 7px;
  gap: 10px;
  /*  border-radius: 5px; */
  letter-spacing: 1px;
  user-select: none;
  .calendar-header {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 100%;
    .range-first-day,
    .range-last-day {
      width: 100%;
    }
    input {
      width: 100%;
      padding: 5px;
      text-align: center;
      border-radius: 3px;
      border: 0.01rem solid gray;
    }
  }
  .month {
    margin: 10px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .left-arrow,
    .right-arrow {
      width: 20px;
      height: 20px;
      object-fit: cover;
      background: #cbcbcb;
      border-radius: 5px;
      padding: 3px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        scale: 0.95;
        background: #ababab;
      }
    }
    img {
      width: 18px;
      height: 18px;
      object-fit: cover;
    }
  }
  .calendar {
    width: 100%;

    .week-days {
      display: flex;
      margin: 10px 0;
      & > div {
        text-align: center;
        width: calc(100% / 7);
      }
    }
    .days-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
      text-align: center;
      background: rgb(255, 255, 255);
      font-size: 0.9rem;

      .highlighted {
        background: #c2ddbe !important;
      }
      .highlighted-red {
        background: #b85252 !important;
      }
      .startDate {
        background: linear-gradient(
          135deg,
          #ffffff00 0%,
          #ffffff00 50%,
          #a7c2a3 50%,
          #a7c2a3 100%
        ) !important;
      }
      .startDate-red {
        background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 50%,
          #b85252 50%,
          #b85252 100%
        ) !important;
      }
      .endDate {
        background: linear-gradient(
          135deg,
          #a7c2a3 0%,
          #a7c2a3 50%,
          #ffffff00 50%,
          #ffffff00 100%
        ) !important;
      }
      .endDate-red {
        background: linear-gradient(
          135deg,
          #b85252 0%,
          #b85252 50%,
          #ffffff00 50%,
          #ffffff00 100%
        ) !important;
      }
      .startDate-hover {
        background: linear-gradient(
          135deg,
          #ffffff00 0%,
          #ffffff00 50%,
          #b9ddb3 50%,
          #b9ddb3 100%
        ) !important;
      }
      .startDate-hover-red {
        background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 50%,
          #a73434 50%,
          #a73434 100%
        ) !important;
      }

      .endDate-hover {
        background: linear-gradient(
          135deg,
          #b9ddb3 0%,
          #b9ddb3 50%,
          #ffffff00 50%,
          #ffffff00 100%
        ) !important;
      }
      .endDate-hover-red {
        background: linear-gradient(
          135deg,
          #a73434 0%,
          #a73434 50%,
          #ffffff00 50%,
          #ffffff00 100%
        ) !important;
      }

      .evening-blocked-selected {
        background: linear-gradient(
          135deg,
          #a7c2a3 0%,
          #a7c2a3 50%,
          #d79c9c 50%,
          #d79c9c 100%
        ) !important;
      }
      .evening-blocked-selected-red {
        background: linear-gradient(
          135deg,
          #b85252 0%,
          #b85252 50%,
          #d79c9c 50%,
          #d79c9c 100%
        ) !important;
      }
      .evening-blocked-hover {
        background: linear-gradient(
          135deg,
          #b9ddb3 0%,
          #b9ddb3 50%,
          #d79c9c 50%,
          #d79c9c 100%
        ) !important;
      }
      .evening-blocked-hover-red {
        background: linear-gradient(
          135deg,
          #a73434 0%,
          #a73434 50%,
          #d79c9c 50%,
          #d79c9c 100%
        ) !important;
      }
      .evening-blocked-hover {
        background: linear-gradient(
          135deg,
          #b9ddb3 0%,
          #b9ddb3 50%,
          #d79c9c 50%,
          #d79c9c 100%
        ) !important;
      }
      .evening-blocked {
        background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 50%,
          #d79c9c 50%,
          #d79c9c 100%
        );
      }

      .morning-blocked {
        background: linear-gradient(
          135deg,
          #d79c9c 0%,
          #d79c9c 50%,
          rgba(255, 255, 255, 0) 50%,
          rgba(255, 255, 255, 0) 100%
        );
      }
      .morning-blocked-selected {
        background: linear-gradient(
          135deg,
          #d79c9c 0%,
          #d79c9c 50%,
          #a7c2a3 50%,
          #a7c2a3 100%
        ) !important;
      }
      .morning-blocked-selected-red {
        background: linear-gradient(
          135deg,
          #d79c9c 0%,
          #d79c9c 50%,
          #b85252 50%,
          #b85252 100%
        ) !important;
      }
      .morning-blocked-hover {
        background: linear-gradient(
          135deg,
          #d79c9c 0%,
          #d79c9c 50%,
          #b9ddb3 50%,
          #b9ddb3 100%
        ) !important;
      }
      .morning-blocked-hover-red {
        background: linear-gradient(
          135deg,
          #d79c9c 0%,
          #d79c9c 50%,
          #a73434 50%,
          #a73434 100%
        ) !important;
      }
      .stop {
        user-select: none;
        pointer-events: none;
      }
      .added-day {
        user-select: none;
        pointer-events: none;
      }
      .evening-stop {
        user-select: none;
        pointer-events: none;
      }
      .edit {
        user-select: default;
        pointer-events: all;
      }
      .day-blocked {
        background: #d79c9c;
        cursor: default;
      }
      .starteDate-block {
        background: linear-gradient(
          135deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 50%,
          #b85252 50%,
          #b85252 100%
        ) !important;
      }
      .day {
        position: relative;
        /*      border: 0.005rem solid rgb(211, 211, 211); */
        aspect-ratio: 1/1;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        font-size: 0.8;
        .rate {
          font-size: 0.7rem;
          letter-spacing: 0.05rem;
        }
      }

      .disabled-day {
        pointer-events: none;
        border: none;
        background: #cecece;
        color: #8c8c8c;
        cursor: not-allowed;
      }
    }
  }
}
