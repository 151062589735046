.slideshow {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  .slides {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  .prevButton,
  .nextButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
    border-radius: 5px;
    font-size: 1.5rem;
    width: 24px;
    height: 24px;
    background-color: var(--transparent-white);
    border: none;
    cursor: pointer;
    color: var(--taupe);
    transition: 0.5s;
    backdrop-filter: blur(1px);
    &:hover {
      color: var(--light-black);
      background-color: var(--white);
    }
  }
  .prevButton,
  .nextButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .prevButton {
    left: 10px;
  }
  .nextButton {
    right: 10px;
  }
  .more-button {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 10px;
    width: 110px;
  }
}
