.form {
  width: 100%;
  position: sticky;
  height: min-content;
  top: 20%;
  background: var(--white);
  min-height: 300px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  margin: 0 auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  input,
  textarea {
    background: var(--white);
  }
  .calendar-client {
    width: 95%;
    left: 50%;
    top: 10px;
    transform: translateX((-50%));
    z-index: 50;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px;
    @media screen and (max-width: 925px) {
      width: 75%;
    }
    @media screen and (max-width: 650px) {
      width: 95%;
    }
    @media screen and (max-width: 400px) {
      width: 105%;
    }
  }
  .input-dates,
  .input-name,
  .input-email,
  .travelers,
  .input-phone,
  textarea {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    resize: none;
  }
  .input-dates {
    width: 100%;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--olive-green);
  }
  .travelers {
    display: flex;
    gap: 15px;
    /*     align-items: flex-end; */
    margin-bottom: 10px;
    .travelers-input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 7px;
      input:hover {
        cursor: grab;
      }
      input:active {
        cursor: grabbing;
      }
      input[type="range"] {
        -webkit-appearance: none;
        appearance: none;
        background: transparent;

        width: 7rem;
      }
      input[type="range"]::-webkit-slider-runnable-track {
        background: var(--light-olive-green);
        border-radius: 50px;
        height: 0.5rem;
      }
      input[type="range"]::-moz-range-track {
        background: var(--light-olive-green);
        border-radius: 50px;
        height: 0.5rem;
      }

      input[type="range"]::-moz-range-thumb {
        margin-top: -4px;
        border-radius: 50px;
        background-color: var(--white);
        border: 1px solid var(--olive-green);
        height: 1rem;
        width: 1rem;
      }
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        border-radius: 50px;
        border: 1px solid var(--olive-green);
        margin-top: -4px;
        background-color: var(--white);
        height: 1rem;
        width: 1rem;
      }
      input[type="range"]:focus {
        outline: none;
      }
    }
  }
  .button {
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    font-size: 1.3rem;
    background: var(--olive-green);
    font-weight: 700;
    letter-spacing: 1px;
    color: var(--white);
    border: 1px solid lightgray;
    cursor: pointer;
    transition: 0.4s;
    &:hover {
      background: var(--light-olive-green);
    }
  }
  .total {
    border: 0 none;
    font-size: 1.2rem;
    max-width: 50px;
    font-weight: 500;
    &:focus {
      border: 0 none;
      outline: none;
    }
  }
}
