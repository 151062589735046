.login {
  position: absolute;
  z-index: 900;
  width: 300px;
  height: 300px;
  background: #ffffff;
  left: 50%;
  border-radius: 10px;
  top: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 20px;
  align-items: center;
  .close-modal {
    position: absolute;
    height: 23px;
    cursor: pointer;
    width: 23px;
    top: 15px;
    right: 15px;
  }
  h4 {
    font-size: 1.5rem;
  }
  .inputs-login {
    width: 100%;

    display: flex;
    gap: 20px;
    flex-direction: column;
    & > div {
      justify-content: space-between;
      display: flex;
      gap: 15px;
      input {
        padding: 3px 5px;
        border: 1px solid grey;
        border-radius: 3px;
        font-weight: 600;
      }
    }
    input {
      background: #eaf1f1;
      max-width: 160px;
    }
  }
}
