$font-1: "Satoshi Light";
.home {
  overflow-x: hidden;
  .main-container {
    width: 100%;

    .main {
      width: 100%;

      height: 100vh;
      height: 100svh;
      position: relative;

      video {
        width: 100%;
        height: 100vh;
        height: 100svh;
        object-fit: cover;
      }

      .header-main-container {
        gap: 17px;
        position: absolute;
        text-align: center;
        display: flex;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 35px;
        background: var(--transparent-black);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--white);

        h1 {
          font-size: 3.4rem;
        }

        p {
          font-size: 1.4rem;
        }

        .chevron-down {
          font-size: 2.4rem;
          transition: 0.3s;
        }

        @media screen and (max-width: 510px) {
          padding: 15px;

          h1 {
            font-size: 2.4rem;
          }

          p {
            font-size: 1.1rem;
          }
        }
      }

      p {
        font-size: 1.5rem;
      }
    }

    .overview {
      .intro {
        display: flex;
        align-items: center;
        text-align: left;
        padding: 20px 50px;
        gap: 20px;
        background-color: var(--white);
        font-size: 1.2rem;
        color: var(--light-black);
        .circle-img {
          height: 170px;
          width: 170px;
          object-fit: cover;
          border-radius: 500px;
        }
        @media screen and (max-width: 750px) {
          flex-direction: column;
          padding: 0;
          gap: 0;
          text-align: center;
          p {
            padding: 10px;
          }
          .circle-img {
            height: 180px;
            width: 100%;
            border-radius: 0px;
            object-position: center;
          }
        }
        @media screen and (max-width: 450px) {
          p {
            font-size: 1rem;
          }
        }
      }
      .accommodations-cards {
        display: flex;
        gap: 30px;
        max-width: 95%;
        margin: 30px auto;

        .accommodation-card {
          max-width: 800px;
          height: min-content;
          text-align: left;
          height: 600px;
          margin: 0px auto;

          border-radius: 5px;
          display: flex;
          flex-direction: column;
          gap: 27px;

          .title-share {
            padding-right: 10px;
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .accomodation-title {
              font-weight: 800;
            }
          }

          .slideshow {
            min-height: 300px;
          }

          .accomodation-text-container {
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;

              @media screen and (max-width: 1000px) {
                -webkit-line-clamp: 3;
              }
            }
          }
        }

        @media screen and (max-width: 840px) {
          max-width: 100%;
          margin: 10px auto;
          flex-direction: column;
          gap: 0px;
          .accommodation-card {
            padding: 20px;
            height: min-content;
            gap: 15px;
          }
        }
        @media screen and (max-width: 450px) {
          .accommodation-card {
            padding: 10px;
            gap: 10px;
          }
        }
      }
    }
  }
}
